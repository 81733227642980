import React from 'react';
import { TextArea } from 'semantic-ui-react';
import { NavItem, NavLink } from 'design-react-kit/dist/design-react-kit';

export default (props) => {
  const { handleTitleChange, handleTitleClick, uid, panel, active } = props;

  return (
    <NavItem>
      <NavLink
        href="#"
        active={active}
        className="tab-title"
        onClick={(e) => {
          handleTitleClick();
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <TextArea
          fluid
          className="input-tab-title"
          transparent
          placeholder="Inserisci il titolo..."
          value={panel?.title || ''}
          onClick={(e) => {
            handleTitleClick();
            e.stopPropagation();
            e.preventDefault();
          }}
          rows="2"
          onChange={(e) => handleTitleChange(e, [uid, panel])}
        />
      </NavLink>
    </NavItem>
  );
};
