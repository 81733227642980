import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ConditionalEmbed } from 'volto-gdpr-privacy';

const View = ({ data }) => {
  const [rootElement, setRootElement] = useState(null);
  const script = useRef(null);
  const style = useRef(null);

  // Inspired by
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const callbackRef = useCallback((node) => {
    if (node !== null) {
      setRootElement(node);
    }
  }, []);

  useEffect(() => {
    if (rootElement && data.script && script.current === null) {
      const scriptEl = document.createElement('script');
      scriptEl.src = data.script;
      scriptEl.async = true;
      script.current = scriptEl;
      rootElement.appendChild(scriptEl);
    }
    if (rootElement && data.styles && style.current === null) {
      const styleEl = document.createElement('style');
      styleEl.innerHTML = `@import url("${data.styles.replace(/"/g, '\\"')}")`;
      style.current = styleEl;
      rootElement.appendChild(styleEl);
    }
  }, [data, rootElement]);

  return (
    <ConditionalEmbed code={data.html}>
      <div
        className={cx('block html script-embed', {
          // 'full-width': data.showFullWidth,
          // 'bg-light': data.bgColor,
        })}
        ref={callbackRef}
      >
        <div className="embed-wrapper">
          <div dangerouslySetInnerHTML={{ __html: data.html }} />
        </div>
      </div>
    </ConditionalEmbed>
  );
};

View.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      html: PropTypes.string.isRequired,
      script: PropTypes.string,
      styles: PropTypes.string,
      showFullWidth: PropTypes.bool,
      bgColor: PropTypes.bool,
    }),
  ).isRequired,
};

export default View;
