import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ListingLinkMore } from '@italia/components/ItaliaTheme';
import BodyWrapper from './BodyWrapper';
import Block from './Block';

const View = ({ data, id }) => {
  const intl = useIntl();

  return (
    <div className="block full-width">
      <div className="elenco-link">
        <BodyWrapper data={data} inEditMode={false}>
          {data.linkListing?.map((link, index) => (
            <Block
              key={link['@id']}
              data={link}
              inEditMode={false}
              intl={intl}
            />
          )) ?? null}
        </BodyWrapper>
        <ListingLinkMore href={data.linkHref} className="mt-5" />
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
};

export default View;
