import React from 'react';
import { defineMessages } from 'react-intl';
import { TextEditorWidget } from '@italia/components/ItaliaTheme';
import {
  Card,
  CardBody,
  CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import redraft from 'redraft';
import { AreaIcon } from '@package/components/icons';
import config from '@plone/volto/registry';

const messages = defineMessages({
  text: {
    id: 'Type text…',
    defaultMessage: 'Digita un testo…',
  },
});

const Block = ({ data, inEditMode, selected, onChange, intl }) => {
  return (
    <Card className="card-bg" noWrapper={true} tag="div">
      <CardBody tag="div">
        <AreaIcon icon={data.area_icon} />
        <CardTitle tag="h3" className="pt-4">
          <strong>{data.area_title}</strong>
        </CardTitle>
        {inEditMode ? (
          <TextEditorWidget
            data={data}
            fieldName="area_text"
            selected={selected}
            onChangeBlock={onChange}
            placeholder={intl.formatMessage(messages.text)}
          />
        ) : (
          <div className="area_text">
            {redraft(
              data.area_text,
              config.settings.richtextViewSettings.ToHTMLRenderers,
              config.settings.richtextViewSettings.ToHTMLOptions,
            )}
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default Block;
