import React from 'react';
import { defineMessages } from 'react-intl';
import {
  TextEditorWidget,
  FontAwesomeIcon,
} from '@italia/components/ItaliaTheme';
import {
  Card,
  CardBody,
  // CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import redraft from 'redraft';
import config from '@plone/volto/registry';

const messages = defineMessages({
  text: {
    id: 'Type text…',
    defaultMessage: 'Digita un testo…',
  },
});

const Block = ({ data, inEditMode, selected, onChange, intl }) => {
  return (
    <Card className="card-bg" noWrapper={true} tag="div">
      <CardBody tag="div">
        <div className="link-icon">
          <FontAwesomeIcon icon={data.icona} />
        </div>
        {inEditMode ? (
          <TextEditorWidget
            data={data}
            fieldName="elencoLink_text"
            selected={selected}
            onChangeBlock={onChange}
            placeholder={intl.formatMessage(messages.text)}
          />
        ) : (
          <div className="elencoLink_text">
            {redraft(
              data.elencoLink_text,
              config.settings.richtextViewSettings.ToHTMLRenderers,
              config.settings.richtextViewSettings.ToHTMLOptions,
            )}
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default Block;
