export const items = {
  AS: {
    title: 'Area',
    items: [
      {
        id: 'AS-TDM',
        label: 'Tecnologie del Mare',
      },
      {
        id: 'AS-SSV',
        label: 'Salute e Scienze della Vita',
      },
      {
        id: 'AS-SQVT',
        label: 'Sicurezza e Qualità della Vita sul Territorio',
      },
      {
        id: 'AS-Generico',
        label: 'Non classificato',
      },
    ],
  },
  CP: {
    title: 'Campo di intervento',
    items: [
      {
        id: 'CP-PrRicInnGrImp',
        label: 'Processi di ricerca e innovazione nelle grandi imprese',
      },
      {
        id: 'CP-TrTcCoUnImp',
        label:
          'Trasferimento di tecnologie e cooperazione tra università e imprese',
      },
      {
        id: 'CP-InvInfCapAttPMI',
        label:
          'Investimenti in infrastrutture, capacità e attrezzature nelle PMI',
      },
      {
        id: 'CP-SosCluRetImp',
        label: 'Sostegno ai cluster e alle reti di imprese',
      },
      {
        id: 'CP-InvPrGenPMI',
        label: 'Investimenti produttivi generici nelle PMI',
      },
      {
        id: 'CP-InfRicInn',
        label: 'Infrastrutture di ricerca e innovazione',
      },
      {
        id: 'CP-Generico',
        label: 'Non classificato',
      },
    ],
  },
  ATE: {
    title: 'Attività economica',
    items: [
      {
        id: 'ATE-AgrFor',
        label: 'Agricoltura e foreste',
      },
      {
        id: 'ATE-AttArtIntDivIndCre',
        label:
          'Attività artistiche, di intrattenimento e divertimento, industrie creative',
      },
      {
        id: 'ATE-AttConAmbCamCli',
        label: "Attività connesse all'ambiente e ai cambiamenti climatici",
      },
      {
        id: 'ATE-AttSerSan',
        label: 'Attività dei servizi sanitari',
      },
      {
        id: 'ATE-AttAssSocSerPubSocPer',
        label:
          'Attività di assistenza sociale, servizi pubblici, sociali e personali',
      },
      {
        id: 'ATE-AttFinAss',
        label: 'Attività finanziarie e assicurative',
      },
      {
        id: 'ATE-AttImmNolSerImp',
        label: 'Attività immobiliari, noleggio e servizi alle imprese',
      },
      {
        id: 'ATE-AzInfCom',
        label:
          "Azioni di informazione e comunicazione, comprese le telecomunicazioni, le attività dei servizi d'informazione, la programmazione informatica, la consulenza e le attività connesse",
      },
      {
        id: 'ATE-ComIngDett',
        label: "Commercio all'ingrosso e al dettaglio",
      },
      {
        id: 'ATE-Edi',
        label: 'Edilizia',
      },
      {
        id: 'ATE-EneEleGasVapAcqCalAriCon',
        label:
          'Energia elettrica, gas, vapore, acqua calda e aria condizionata',
      },
      {
        id: 'ATE-FabComProEleOtt',
        label: 'Fabbricazione di computer e prodotti di elettronica e ottica',
      },
      {
        id: 'ATE-FabMezTra',
        label: 'Fabbricazione di mezzi di trasporto',
      },
      {
        id: 'ATE-ForAcqFogAttTraRifDec',
        label:
          'Fornitura di acqua, reti fognarie, attività di trattamento dei rifiuti e decontaminazione',
      },
      {
        id: 'ATE-IndEst',
        label:
          "Industria estrattiva (compresa l'estrazione di materiali per la produzione di energia)",
      },
      {
        id: 'ATE-IndAlBev',
        label: 'Industrie alimentari e delle bevande',
      },
      {
        id: 'ATE-IndTesAbb',
        label: "Industrie tessili e dell'abbigliamento",
      },
      {
        id: 'ATE-Ist',
        label: 'Istruzione',
      },
      {
        id: 'ATE-PesAcq',
        label: 'Pesca e acquacoltura',
      },
      {
        id: 'ATE-PubAmm',
        label: 'Pubblica amministrazione',
      },
      {
        id: 'ATE-TraSto',
        label: 'Trasporti e stoccaggio',
      },
      {
        id: 'ATE-TurSerAllRist',
        label: 'Turismo, servizi di alloggio e di ristorazione',
      },
      {
        id: 'ATE-AltIndManSpe',
        label: 'Altre industrie manifatturiere non specificate',
      },
      {
        id: 'ATE-Generico',
        label: 'Altri servizi non specificati',
      },
    ],
  },
  AZ: {
    title: 'Azione',
    items: [
      {
        id: 'AZ-113',
        label:
          "Azione 1.1.3 POR FESR 2014-2020 - Sostegno alla valorizzazione economica dell'innovazione",
      },
      {
        id: 'AZ-124Gen',
        label:
          'Azione 1.2.4 POR FESR 2014- 2020 - Supporto alla realizzazione di progetti  di R&S',
      },
      {
        id: 'AZ-124Pol',
        label:
          'Azione 1.2.4 POR FESR 2014-2020 - Supporto alla realizzazione di progetti di R&S aziende Poli',
      },
      {
        id: 'AZ-124Sav',
        label:
          'Azione 1.2.4 POR FESR 2014-2020 - Supporto alla realizzazione di progetti di R&S Area di crisi complessa savonese',
      },
      {
        id: 'AZ-151',
        label:
          'Azione 1.5.1. del POR FESR 2014-2020 - Sostegno alle infrastrutture di ricerca',
      },
      {
        id: 'AZ-311',
        label:
          'Azione 3.1.1. del POR FESR 2014-2020 - Digitalizzazione delle MPMI',
      },
    ],
  },
  TP: {
    title: 'Tipologia di progetto',
    items: [
      {
        id: 'TP-AtRic',
        label: 'Attività di ricerca',
      },
      {
        id: 'TP-Ampl',
        label: 'Ampliamento',
      },
      {
        id: 'TP-Amm',
        label: 'Ammodernamento',
      },
      {
        id: 'TP-Generico',
        label: 'Non classificato',
      },
    ],
  },
  PR: {
    title: 'Programma',
    items: [
      {
        id: 'PR-Fesr',
        label: 'FESR',
      },
    ],
  },
};
