import VoltoViewTextBlock from '@plone/volto/components/manage/Blocks/Text/View';
import { AreaIcon } from '@package/components/icons';
import { Icon as ItaliaIcon } from '@italia/components/ItaliaTheme';

const View = (props) => {
  const isNotaMetodologica =
    props.data.text?.blocks[0]?.type === 'nota-metodologica';
  const isIconaLinkDownload =
    props.data.text?.blocks[0]?.type === 'icona-link-file';
  return (
    <div className="s3-text-block">
      {isNotaMetodologica && (
        <div className="nota-metodologica">
          <div className="svg-title-wrapper">
            <ItaliaIcon icon="it-note" />
            <span className="nota-title">Nota Metodologica</span>
          </div>
          <VoltoViewTextBlock {...props} />
        </div>
      )}
      {isIconaLinkDownload && (
        <div className="icona-link-file">
          <ItaliaIcon icon="it-download" />
          <VoltoViewTextBlock {...props} />
        </div>
      )}
      {!isIconaLinkDownload && !isNotaMetodologica && (
        <>
          {props.data.area_icon && <AreaIcon icon={props.data.area_icon} />}
          <VoltoViewTextBlock {...props} />
        </>
      )}
    </div>
  );
};

export default View;
