import { Component, memo } from 'react';
import BodyWrapper from './BodyWrapper';
import PropTypes from 'prop-types';
import { ListingLinkMore } from '@italia/components/ItaliaTheme';
import Block from './Block';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { schema } from './schema';

class Edit extends Component {
  render() {
    const blockSchema = schema({
      formData: this.props.data,
      intl: this.props.intl,
    });

    return (
      <div className="elenco-link public-ui">
        <BodyWrapper data={this.props.data} inEditMode={false}>
          {this.props.data.linkListing?.map((link, index) => (
            <Block
              key={link['@id']}
              data={link}
              inEditMode={true}
              selected={false}
              onChange={(link) => {
                const newListing = [...this.props.data.linkListing];
                newListing[index] = link;
                this.props.onChangeBlock(this.props.block, {
                  ...this.props.data,
                  linkListing: newListing,
                });
              }}
              intl={this.props.intl}
            />
          )) ?? null}
        </BodyWrapper>

        <SidebarPortal selected={this.props.selected || false}>
          <BlockDataForm
            block={this.props.block}
            schema={blockSchema}
            onChangeField={(id, value) => {
              this.props.onChangeBlock(this.props.block, {
                ...this.props.data,
                [id]: value,
              });
            }}
            formData={this.props.data}
          />
        </SidebarPortal>

        <ListingLinkMore
          href={this.props.data.linkHref}
          title={this.props.data.linkTitle}
          className="mt-5"
        />
      </div>
    );
  }
}
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  selected: PropTypes.any,
  intl: PropTypes.any,
  onChangeBlock: PropTypes.func.isRequired,
};

export default memo(Edit);
