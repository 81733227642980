import PropTypes from 'prop-types';
import Menu from './Menu';
import { items } from './items';

const Edit = ({ block, data, selected = false, onChangeBlock }) => {
  return (
    <div className="public-ui">
      <div className="filters-block filtri-indicatori-output mb-5">
        <p>
          <strong>Filtra per:</strong>
        </p>
        <div className="menus-wrapper">
          <div className="menus d-flex">
            {Object.keys(items).map((menu) => (
              <Menu
                key={menu}
                title={items[menu].title}
                items={items[menu].items}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;

Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onChangeBlock: PropTypes.func.isRequired,
};
