import React from 'react';
import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';

import { FontAwesomeIcon } from '@italia/components/ItaliaTheme';

const LinksArrow = createBlockStyleButton({
  blockType: 'linksArrow',
  children: <FontAwesomeIcon icon={['fas', 'arrow-right']} size="24px" />,
});

export default React.memo(LinksArrow);
