import { defineMessages } from 'react-intl';

export function schema({ formData, intl }) {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['script', 'styles' /*, 'bgColor', 'showFullWidth' */],
      },
    ],
    properties: {
      script: {
        title: intl.formatMessage(messages.script),
        description: intl.formatMessage(messages.script_desc),
      },
      styles: {
        title: intl.formatMessage(messages.styles),
        description: intl.formatMessage(messages.styles_desc),
      },
      // bgColor: {
      //   title: intl.formatMessage(messages.bgColor),
      //   type: 'boolean',
      // },
      // showFullWidth: {
      //   title: intl.formatMessage(messages.htmlFullWidth),
      //   type: 'boolean',
      // },
    },
    required: [],
  };
}

const messages = defineMessages({
  // bgColor: {
  //   id: 'bgColor',
  //   defaultMessage: 'Mostra lo sfondo del blocco',
  // },
  // htmlFullWidth: {
  //   id: 'htmlFullWidth',
  //   defaultMessage: 'Mostra lo sfondo a tutta larghezza',
  // },
  script: {
    id: 'script_embed_script_title',
    defaultMessage: 'Script URL',
  },
  script_desc: {
    id: 'script_embed_script_description',
    defaultMessage: 'Insert the URL of the script to embed',
  },
  styles: {
    id: 'script_embed_styles_title',
    defaultMessage: 'Styles URL',
  },
  styles_desc: {
    id: 'script_embed_styles_description',
    defaultMessage: 'Insert the URL of the stylesheet to embed',
  },
});
