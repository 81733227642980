import React from 'react';
import Icon from '@plone/volto/components/theme/Icon/Icon';

import DraftJsDropdownButton from '@italia/config/RichTextEditor/ToolbarButtons/DraftJsDropdownButton';
import {
  FontAwesomeIcon,
  Icon as ItaliaIcon,
} from '@italia/components/ItaliaTheme';

import calloutSVG from '@plone/volto/icons/megaphone.svg';

const CalloutsButton = (props) => {
  const createBlockStyleButton = props.draftJsCreateBlockStyleButton.default;

  const options = [
    {
      block_type: 'callout',
      value: createBlockStyleButton({
        blockType: 'callout',
        children: <FontAwesomeIcon icon={['far', 'square']} />,
      }),
    },
    {
      block_type: 'callout-bg',
      value: createBlockStyleButton({
        blockType: 'callout-bg',
        children: <FontAwesomeIcon icon={['fas', 'square']} />,
      }),
    },
    {
      block_type: 'nota-metodologica',
      value: createBlockStyleButton({
        blockType: 'nota-metodologica',
        children: <ItaliaIcon icon="it-note" />,
      }),
    },
    {
      block_type: 'icona-link-file',
      value: createBlockStyleButton({
        blockType: 'icona-link-file',
        children: <ItaliaIcon icon="it-download" />,
      }),
    },
  ];

  return (_props) => (
    <DraftJsDropdownButton
      {..._props}
      optionsList={options}
      content={<Icon name={calloutSVG} size="24px" />}
    />
  );
};

export default CalloutsButton;
