import React from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'design-react-kit/dist/design-react-kit';
import { RenderBlocks } from '@plone/volto/components';
import { getPanels, tabBlockHasValue } from './util';
import { withBlockExtensions } from '@plone/volto/helpers';

import TabContent from './TabContent';

const View = (props) => {
  const { data } = props;
  const panels = getPanels(data.data);
  const metadata = props.metadata || props.properties;
  const [activeIndex, setActiveIndex] = React.useState(0);
  const location = useLocation();

  const keys = {
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
  };

  const onKeyDown = (event, index) => {
    var key = event.keyCode;

    switch (key) {
      case keys.end:
        event.preventDefault();
        // Activate last tab
        setActiveIndex(panels.length - 1);
        break;
      case keys.home:
        event.preventDefault();
        // Activate first tab
        setActiveIndex(0);
        break;

      // Up and down are in keydown
      // because we need to prevent page scroll >:)
      case keys.up:
      case keys.down:
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  // Handle keyup on tabs
  const onKeyUp = (event) => {
    var key = event.keyCode;

    switch (key) {
      case keys.left:
        if (activeIndex > 0) {
          setActiveIndex(activeIndex - 1);
        }
        break;
      case keys.right:
        if (activeIndex < panels.length - 1) {
          setActiveIndex(activeIndex + 1);
        }
        break;
      default:
        break;
    }
  };

  return panels?.length > 0 ? (
    <div className="tabs-block">
      <div className="tabs-wrapper">
        {/* <div className="tabs" role="tablist" aria-label={data.title ?? 'Tabs'}> */}
        <Nav
          tabs
          className="tabs"
          role="tablist"
          aria-label={data.title ?? 'Tabs'}
        >
          {panels.map(([uid, panel], index) => {
            return tabBlockHasValue(panel) ? (
              <NavItem key={`button${uid}`}>
                <NavLink
                  href="#"
                  active={activeIndex === index}
                  className="tab-title"
                  onClick={(e) => {
                    setActiveIndex(index);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => onKeyDown(e, index)}
                  onKeyUp={(e) => onKeyUp(e, index)}
                  role="tab"
                  aria-selected={activeIndex === index}
                  aria-controls={'tabcontent-' + uid}
                  id={'tab-' + uid}
                >
                  {panel.title}
                </NavLink>
              </NavItem>
            ) : null;
          })}
        </Nav>
        {panels.map(([uid, panel], index) => {
          return tabBlockHasValue(panel) ? (
            <TabContent
              key={uid}
              active={activeIndex === index}
              tabindex="0"
              role="tabpanel"
              id={'tabcontent-' + uid}
              aria-labelledby={'tab-' + uid}
              aria-hidden={activeIndex !== index || null}
            >
              <RenderBlocks
                {...props}
                location={location}
                metadata={metadata}
                content={panel}
              />
            </TabContent>
          ) : null;
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default withBlockExtensions(View);
