import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const View = ({ data }) => {
  const location = useLocation();
  const root = useRef(null);

  useEffect(() => {
    if (location.hash && data.anchor === location.hash.replace('#', '')) {
      setTimeout(() => {
        root.current.scrollIntoView({
          block: 'start',
          inline: 'center',
        });
      }, 200);
    }
  }, [data.anchor, location.hash]);

  return <div ref={root} id={data.anchor} />;
};

export default View;
