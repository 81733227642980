import PropTypes from 'prop-types';
import { Icon } from '@italia/components/ItaliaTheme';

const icons = {
  gen: 'fas chart-line',
  tdm: 'fas water',
  ssv: 'fas heartbeat',
  sqv: 'fas leaf',
};

const AreaIcon = ({ icon }) => (
  <span className={`area-icon area-icon--${icon}`}>
    <Icon icon={icons[icon]} />
  </span>
);

export default AreaIcon;

AreaIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)),
};
