import { defineMessages } from 'react-intl';

export function schema({ formData, intl }) {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['area_icon'],
      },
    ],
    properties: {
      area_icon: {
        title: intl.formatMessage(messages.icon),
        description: intl.formatMessage(messages.icon_desc),
      },
    },
    required: [],
  };
}

const messages = defineMessages({
  icon: {
    id: 'text_block_icon_title',
    defaultMessage: 'Icona',
  },
  icon_desc: {
    id: 'text_block_icon_description',
    defaultMessage: "Seleziona l'icona da anteporre al testo",
  },
});
