import { defineMessages, useIntl } from 'react-intl';
import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import VoltoEditTextBlock from '@plone/volto/components/manage/Blocks/Text/Edit';
import { AreaIcon } from '@package/components/icons';
import { Icon as ItaliaIcon } from '@italia/components/ItaliaTheme';
import { schema } from './schema';

const Edit = (props) => {
  const intl = useIntl();
  const isNotaMetodologica =
    props.data.text?.blocks[0]?.type === 'nota-metodologica';
  const isIconaLinkDownload =
    props.data.text?.blocks[0]?.type === 'icona-link-file';

  if (__SERVER__) {
    return <div />;
  }

  return (
    <div className="s3-text-block">
      {isNotaMetodologica && (
        <div className="nota-metodologica nota-edit">
          <div className="svg-title-wrapper">
            <ItaliaIcon icon="it-note" />
            <span className="nota-title">Nota Metodologica</span>
          </div>
          <VoltoEditTextBlock {...props} />
        </div>
      )}
      {isIconaLinkDownload && (
        <div className="icona-link-file">
          <ItaliaIcon icon="it-download" />
          <VoltoEditTextBlock {...props} />
        </div>
      )}
      {!isIconaLinkDownload && !isNotaMetodologica && (
        <>
          {props.data.area_icon && <AreaIcon icon={props.data.area_icon} />}
          <VoltoEditTextBlock {...props} />
        </>
      )}
      <SidebarPortal selected={props.selected || false}>
        <BlockDataForm
          block={props.block}
          schema={schema({
            formData: props.data,
            intl,
          })}
          title={intl.formatMessage(messages.s3_text_block)}
          onChangeField={(id, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [id]: value,
            });
          }}
          formData={props.data}
        />
      </SidebarPortal>
    </div>
  );
};

export default Edit;

const messages = defineMessages({
  s3_text_block: {
    id: 's3_text_block',
    defaultMessage: 'Testo',
  },
});
