import { useEffect, useState } from 'react';
import { Button } from 'design-react-kit/dist/design-react-kit';
import { FontAwesomeIcon } from '@italia/components/ItaliaTheme';
import Menu from './Menu';
import { items } from './items';

const View = ({ data }) => {
  const [filters, setFilters] = useState(new Set());

  // Filters need to be in the form `menu-id`
  // i.e. AS-TDM
  const onChange = (id = '') => {
    setFilters((prev) => {
      const next = new Set(prev);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  };

  useEffect(() => {
    if (window.updateFilterLibrary) {
      const preparedFilters = [...filters].reduce((acc, filter) => {
        const newAcc = { ...acc };
        const [menu, id] = filter.split('-');
        if (!menu || !id) return newAcc;

        const prevItems = acc[menu] ?? [];
        return {
          ...newAcc,
          [menu]: [...prevItems, filter],
        };
      }, {});
      window.updateFilterLibrary(preparedFilters);
    }
  }, [filters]);

  // Add 'checked' property to every item
  const menuItems = Object.keys(items).reduce(
    (acc, menu) => ({
      ...acc,
      [menu]: {
        ...items[menu],
        items: items[menu].items.map((item) => ({
          ...item,
          checked: filters.has(item.id),
        })),
      },
    }),
    {},
  );

  return (
    <div className="filters-block filtri-indicatori-output mb-5">
      <p>
        <strong>Filtra per:</strong>
      </p>
      <div className="menus-wrapper">
        <div className="menus d-flex">
          {Object.keys(menuItems).map((menu) => (
            <Menu
              key={menu}
              title={menuItems[menu].title}
              items={menuItems[menu].items}
              hasActiveChild={[...filters].reduce(
                (acc, f) => acc || f.startsWith(menu),
                false,
              )}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
      <div className="selected-filters mt-3">
        {[...filters].map((filter) => {
          const [menu, id] = filter.split('-');
          if (!menu || !id) return null;

          const label =
            menuItems[menu].items.find((i) => i.id === filter)?.label ?? id;

          return (
            <Button
              key={filter}
              outline
              color="link"
              onClick={() => {
                onChange(filter);
              }}
              className="remove-filter px-0 py-2"
            >
              <strong>{menuItems[menu].title}:</strong> {label}
              <FontAwesomeIcon icon={['fas', 'times']} />
            </Button>
          );
        })}
        {filters.size > 1 && (
          <Button
            outline
            color="link"
            onClick={() => {
              setFilters(new Set());
            }}
            className="remove-filter remove-all py-2"
          >
            <strong>RIMUOVI FILTRI</strong>
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default View;
