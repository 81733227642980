import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Input,
  FormGroup,
  Label,
} from 'design-react-kit/dist/design-react-kit';
import { FontAwesomeIcon } from '@italia/components/ItaliaTheme';

function Menu({ title = '', items = [], hasActiveChild, onChange = () => {} }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen((isOpen) => !isOpen)}
      className="mr-2"
    >
      <DropdownToggle
        color="primary"
        outline
        className={cx('filters-toggle', {
          'has-active-child': hasActiveChild,
        })}
      >
        {title}{' '}
        <FontAwesomeIcon icon={['fas', isOpen ? 'caret-up' : 'caret-down']} />
      </DropdownToggle>
      <DropdownMenu
        className="py-3 px-4"
        positionFixed
        modifiers={{
          placement: 'bottom-start',
        }}
      >
        {items.map(({ id, label, checked }) => (
          <FormGroup check key={id} className="my-3">
            <Input
              id={id}
              type="checkbox"
              checked={checked}
              onChange={() => {
                onChange(id);
              }}
            />
            <Label for={id} check>
              {label}
            </Label>
          </FormGroup>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default Menu;

Menu.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    }),
  ),
  onChange: PropTypes.func.isRequired,
};
