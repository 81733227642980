import React from 'react';
import ItaliaRichTextConfig from '@italia/config/RichTextEditor/config';
import ArrowButton from './ToolbarButtons/LinksArrow';
import FromHTML from '@plone/volto/config/RichTextEditor/FromHTML';
import Blocks from '@plone/volto/config/RichTextEditor/Blocks';

import { FontAwesomeIcon } from '@italia/components/ItaliaTheme';

const renderHTMLBlock = (child) => {
  return child.map((subchild) => {
    if (Array.isArray(subchild)) {
      return subchild.map((subchildren) => {
        if (typeof subchildren === 'string') {
          const last = subchildren.split('\n').length - 1;
          return subchildren.split('\n').map((item, index) => (
            <React.Fragment key={index}>
              {item}
              {index !== last && <br />}
            </React.Fragment>
          ));
        } else {
          return subchildren;
        }
      });
    } else {
      return subchild;
    }
  });
};

const ItaliaFromHTMLCustomBlockFn = (element) => {
  let ret = FromHTML(element); //get default from plone/volto

  if (!ret) {
    if (element.className === 'callout-bg') {
      ret = {
        type: 'callout-bg',
      };
    } else if (element.className === 'draftjs-buttons') {
      ret = {
        type: 'buttons',
      };
    } else if (element.className === 'draftjs-text-larger') {
      ret = {
        type: 'TEXT_LARGER',
      };
    } else if (element.className === 'draftjs-arrow') {
      ret = {
        type: 'linksArrow',
      };
    }
  }
  return ret;
};

export default function applyConfig(configVolto) {
  let config = ItaliaRichTextConfig(configVolto);
  const baseEditorSettings = config.settings.richtextEditorSettings;
  config.settings.richtextEditorSettings = (props) => {
    const { extendedBlockRenderMap, blockStyleFn } = Blocks(props);

    const italiaBlockStyleFunction = (contentBlock) => {
      const type = contentBlock.getType();

      let r = blockStyleFn(contentBlock) || '';
      r = r.length > 0 ? ' ' : r;

      const styles = {
        'align-center': 'text-center',
        'align-right': 'text-right',
        'align-justify': 'text-justify',
        callout: 'callout',
        'callout-bg': 'callout-bg',
        buttons: 'draftjs-buttons',
        linksArrow: 'draftjs-arrow',
      };

      r += styles[type] ?? '';

      return r;
    };

    const { immutableLib } = props;
    const { Map } = immutableLib;

    const blockRenderMap = Map({
      linksArrow: {
        element: 'p',
      },
    });

    const baseConfig = baseEditorSettings(props);
    baseConfig.richTextEditorInlineToolbarButtons.splice(10, 0, ArrowButton);
    baseConfig.FromHTMLCustomBlockFn = ItaliaFromHTMLCustomBlockFn;
    baseConfig.blockStyleFn = italiaBlockStyleFunction;
    baseConfig.extendedBlockRenderMap =
      extendedBlockRenderMap.merge(blockRenderMap);
    return baseConfig;
  };

  config.settings.richtextViewSettings.ToHTMLRenderers.blocks = {
    ...config.settings.richtextViewSettings.ToHTMLRenderers.blocks,
    linksArrow: (
      children,
      { keys }, // arrow è il blockType che inserisci nell'ArrowButton che crei tu
    ) =>
      children.map((child, i) => (
        <p id={keys[i]} key={keys[i]} className="draftjs-arrow">
          {renderHTMLBlock(child)}
          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
        </p>
      )),
  };

  return config;
}
