/**
 * FooterMain component.
 * @module components/Footer/FooterMain
 * Customizations:
 * - Removed FooterNavigation
 * - Changed FooterInfos with custom footer text
 */

import React from 'react';
import { Container, Row, Col } from 'design-react-kit/dist/design-react-kit';

import { UniversalLink } from '@plone/volto/components';
import {
  // FooterNavigation,
  // FooterInfos,
  LogoFooter,
  BrandTextFooter,
  FooterSocials,
} from '@italia/components/ItaliaTheme/';

import logofesr from '@package/components/footer/por-FESR_bianco.svg';

/**
 * FooterMain component class.
 * @class FooterMain
 * @extends Component
 */
const FooterMain = () => {
  return (
    <div className="it-footer-main">
      <Container tag="div">
        <section>
          <Row className="clearfix" tag="div">
            <Col sm={12} tag="div" widths={['xs', 'sm', 'md', 'lg', 'xl']}>
              <div className="it-brand-wrapper">
                <UniversalLink href="/">
                  <LogoFooter />
                  <BrandTextFooter />
                </UniversalLink>
              </div>
            </Col>
          </Row>
        </section>
        {/* <section>
          <FooterNavigation />
        </section> */}
        <section className="pb-4">
          {/* <FooterInfos /> */}
          <Row tag="div">
            <Col
              lg={4}
              md={5}
              className="pb-2"
              tag="div"
              widths={['xs', 'sm', 'md', 'lg', 'xl']}
            >
              <h4>Contatti</h4>
              <address>
                <strong>Regione Liguria</strong>
                <br />
                P.IVA 009849050109
                <br />
                via Fieschi 15
                <br />
                16121 Genova
                <br />
                tel. centralino +39 010 54851
              </address>
              <p>
                <strong>URP Liguriainforma</strong>
                <br />
                Numero verde 800 445 445
                <br />
                Fax +39 010 5488742
              </p>
              <p>
                <strong>Social</strong>
              </p>
              <FooterSocials />
            </Col>
            <Col
              lg={8}
              md={7}
              className="pb-2"
              tag="div"
              widths={['xs', 'sm', 'md', 'lg', 'xl']}
            >
              <img
                src={logofesr}
                alt="Loghi di POR FESR"
                width="804"
                height="159"
                loading="lazy"
                className="img-fluid"
              />
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default FooterMain;
