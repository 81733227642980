import { Icon } from '@plone/volto/components';
import VoltoSelectWidget from '@plone/volto/components/manage/Widgets/SelectWidget';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import checkSVG from '@plone/volto/icons/check.svg';
import { AreaIcon } from '@package/components/icons';

const AreaOption = injectLazyLibs('reactSelect')((props) => {
  const { Option } = props.reactSelect.components;

  return (
    <Option {...props}>
      <div>
        {props.value && <AreaIcon icon={props.value} />}
        {props.label}
      </div>
      {!props.isFocused && !props.isSelected && (
        <Icon name={checkSVG} size="24px" color="transparent" />
      )}
      {props.isFocused && !props.isSelected && (
        <Icon name={checkSVG} size="24px" color="#b8c6c8" />
      )}
      {props.isSelected && <Icon name={checkSVG} size="24px" color="#007bc1" />}
    </Option>
  );
});

const AreaSelectWidget = (props) => {
  return (
    <VoltoSelectWidget
      {...props}
      customOptionStyling={AreaOption}
      isMulti={false}
      choices={[
        ['gen', 'Indicatori generici'],
        ['tdm', 'Tecnologie del mare'],
        ['ssv', 'Salute e scienza della vita'],
        ['sqv', 'Sicurezza e qualità della vita sul territorio'],
      ]}
      noValueOption={false}
    />
  );
};

export default AreaSelectWidget;
