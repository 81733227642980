/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import italiaaddonsvoltoMultilingualWidget from '@italia/addons/volto-multilingual-widget';
import italiaaddonsvoltoRssBlock from '@italia/addons/volto-rss-block';
import italiaaddonsvoltoVenue from '@italia/addons/volto-venue';
import italiaaddonsvoltoSubblocks from '@italia/addons/volto-subblocks';
import italiaaddonsvoltoDropdownmenu from '@italia/addons/volto-dropdownmenu';
import italiaaddonsvoltoSubsites from '@italia/addons/volto-subsites';
import italiaaddonsvoltoSocialSettings from '@italia/addons/volto-social-settings';
import italiaaddonsvoltoEditablefooter from '@italia/addons/volto-editablefooter';
import italiaaddonsvoltoBlocksWidget from '@italia/addons/volto-blocks-widget';
import italiaaddonsvoltoGoogleAnalytics from '@italia/addons/volto-google-analytics';
import italiaaddonsvoltoSecondarymenu from '@italia/addons/volto-secondarymenu';
import italiaaddonsvoltoFormBlock from '@italia/addons/volto-form-block';
import italiaaddonsvoltoSubfooter from '@italia/addons/volto-subfooter';
import italiaaddonsvoltoCustomerSatisfaction from '@italia/addons/volto-customer-satisfaction';
import italiaaddonsvoltoGdprPrivacy from '@italia/addons/volto-gdpr-privacy';
import eeacmsvoltoMatomo from '@eeacms/volto-matomo';
import kitconceptvoltoBlocksGrid from '@kitconcept/volto-blocks-grid';
import eeacmsvoltoAccordionBlock from '@eeacms/volto-accordion-block';

const addonsInfo = {};
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [italiaaddonsvoltoMultilingualWidget, italiaaddonsvoltoRssBlock, italiaaddonsvoltoVenue, italiaaddonsvoltoSubblocks, italiaaddonsvoltoDropdownmenu, italiaaddonsvoltoSubsites, italiaaddonsvoltoSocialSettings, italiaaddonsvoltoEditablefooter, italiaaddonsvoltoBlocksWidget, italiaaddonsvoltoGoogleAnalytics, italiaaddonsvoltoSecondarymenu, italiaaddonsvoltoFormBlock, italiaaddonsvoltoSubfooter, italiaaddonsvoltoCustomerSatisfaction, italiaaddonsvoltoGdprPrivacy, eeacmsvoltoMatomo, kitconceptvoltoBlocksGrid, eeacmsvoltoAccordionBlock];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
