import { defineMessages } from 'react-intl';
import { defaultIconWidgetOptions } from '@italia/helpers/index';

export function schema({ formData, intl }) {
  return {
    title: intl.formatMessage(messages.title),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'linkListing', 'linkTitle', 'linkHref'],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.block_title),
      },
      linkListing: {
        widget: 'object_list',
        title: intl.formatMessage(messages.boxes),
        schema: objectListSchema({ formData, intl }),
      },
      linkTitle: {
        title: intl.formatMessage(messages.LinkTitle),
      },
      linkHref: {
        title: intl.formatMessage(messages.LinkTo),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
    },
    required: [],
  };
}

function objectListSchema({ formData, intl }) {
  return {
    title: intl.formatMessage(messages.object_title),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['icona'],
      },
    ],
    properties: {
      icona: {
        title: intl.formatMessage(messages.icon),
        description: intl.formatMessage(messages.icon_desc),
        widget: 'link_icon',
        defaultOptions: defaultIconWidgetOptions,
      },
    },
    required: [],
  };
}

const messages = defineMessages({
  title: {
    id: 's3_elencoLink',
    defaultMessage: 'Dashboard degli indicatori di specializzazione',
  },
  block_title: {
    id: 's3_block_title',
    defaultMessage: 'Titolo',
  },
  boxes: {
    id: 's3_boxes',
    defaultMessage: 'Riquadri',
  },
  LinkTitle: {
    id: 'Link title',
    defaultMessage: 'Link Title',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  object_title: {
    id: 's3_link',
    defaultMessage: 'Card Icona',
  },
  icon: {
    id: 'text_block_icon_title',
    defaultMessage: 'Icona',
  },
  icon_desc: {
    id: 'text_block_icon_description',
    defaultMessage: "Seleziona l'icona da anteporre al testo",
  },
  elencoLink_title: {
    id: 's3_elencoLink_title',
    defaultMessage: 'Titolo',
  },
});
