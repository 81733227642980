import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';

const Edit = ({ block, data, selected = false, onChangeBlock }) => {
  const intl = useIntl();

  return (
    <div className="public-ui">
      <pre id={data.anchor}>
        {intl.formatMessage(messages.title)}: {data.anchor}
      </pre>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          block={block}
          schema={{
            title: intl.formatMessage(messages.title),
            fieldsets: [
              {
                id: 'default',
                title: 'Default',
                fields: ['anchor'],
              },
            ],
            properties: {
              anchor: {
                title: intl.formatMessage(messages.anchor),
                description: intl.formatMessage(messages.anchor_desc),
              },
            },
            required: ['anchor'],
          }}
          title={intl.formatMessage(messages.title)}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
    </div>
  );
};

export default Edit;

Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onChangeBlock: PropTypes.func.isRequired,
};

const messages = defineMessages({
  title: {
    id: 'anchor_title',
    defaultMessage: 'Ancora',
  },
  anchor: {
    id: 'anchor_anchor',
    defaultMessage: 'Ancora',
  },
  anchor_desc: {
    id: 'anchor_anchor_desc',
    defaultMessage: "Inserisci l'identificatore dell'ancora",
  },
});
