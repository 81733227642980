/**
 * Add your config changes here.
 * @module config
 */
import { omit } from 'lodash';
import applyItaliaConfig from '@italia/config/italiaConfig';

import ScriptEmbedEdit from '@package/components/blocks/ScriptEmbed/Edit';
import ScriptEmbedView from '@package/components/blocks/ScriptEmbed/View';
import TextEdit from '@package/components/blocks/Text/Edit';
import TextView from '@package/components/blocks/Text/View';
import DashboardContestoEdit from '@package/components/blocks/DashboardContesto/Edit';
import DashboardContestoView from '@package/components/blocks/DashboardContesto/View';
import ElencoLinkEdit from '@package/components/blocks/ElencoLink/Edit';
import ElencoLinkView from '@package/components/blocks/ElencoLink/View';
import AnchorView from '@package/components/blocks/Anchor/View';
import AnchorEdit from '@package/components/blocks/Anchor/Edit';
import TabsView from '@package/components/blocks/Tabs/View';
import TabsEdit from '@package/components/blocks/Tabs/Edit';
import FiltriIndicatoriOutputView from '@package/components/blocks/FiltriIndicatoriOutput/View';
import FiltriIndicatoriOutputEdit from '@package/components/blocks/FiltriIndicatoriOutput/Edit';

import AreaSelectWidget from '@package/components/widgets/AreaSelectWidget/AreaSelectWidget';
import IconWidget from '@italia/components/ItaliaTheme/manage/Widgets/IconWidget';

import linkSvg from '@plone/volto/icons/link.svg';
import filterSvg from '@plone/volto/icons/filter.svg';
import noteSvg from 'bootstrap-italia/src/svg/it-note.svg';
import tabsSvg from '@package/components/icons/tabs.svg';

import ItaliaRichTextConfig from '../src/config/RichTextEditor';

import '@plone/volto/config';

const serverConfig =
  typeof __SERVER__ !== 'undefined' &&
  __SERVER__ &&
  process.env.USE_GRAPHQL_PROXY
    ? {
        expressMiddleware: [
          require('./express-middleware/graphqlproxy').default(),
        ],
      }
    : { expressMiddleware: [] };

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config = ItaliaRichTextConfig(config);

  config.settings = {
    ...config.settings,

    supportedLanguages: ['it'],
    defaultLanguage: 'it',
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'S3 Monitoraggio',
      siteSubtitle: 'SMART SPECIALISATION STRATEGY',
      parentSiteTitle: 'Regione Liguria',
      parentSiteURL: 'https://www.regione.liguria.it',
      subsiteParentSiteTitle: 'S3 Monitoraggio',
      enableCustomerSatisfaction: false, // false per disabilitare
      enableCustomerSatisfactionCaptcha: false,
      enableVoltoFormBlockCaptcha: false,
      //arLoginUrl: '/it/area-riservata',
      //arLogoutUrl: '/logout',
      // smallFooterLinks: {
      //   default: [{ title: 'Mappa del sito', url: '/sitemap' }],
      //   it: [{ title: 'Mappa del sito', url: '/it/sitemap' }],
      //   en: [{ title: 'Sitemap', url: '/en/sitemap' }],
      // },
      // headerslimTertiaryMenu: {
      //   it: [
      //     {
      //       title: 'Metodologia e approccio',
      //       url: '/strategia/metodologia-e-approccio',
      //     },
      //   ],
      // },
    },
    // appExtras: [
    //   ...config.settings.appExtras,
    // ],
    expressMiddleware: [
      ...(config.settings.expressMiddleware || []),
      ...serverConfig.expressMiddleware,
    ],
    serverConfig: {
      ...config.settings.serverConfig,
      expressMiddleware: [
        ...(config.settings.serverConfig.expressMiddleware || []),
        ...serverConfig.expressMiddleware,
      ],
    },
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it.description =
    "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti. Per saperne di più consulta la nostra <a href='/privacy-policy' target='_blank'>Privacy Policy.</a>";
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical.choices = [
    {
      config_key: 'MATOMO',
      text: {
        it: {
          title: 'Web Analytics Italia',
          description:
            "I cookie di Web Analytics Italia sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
        },
        en: {
          title: 'Web Analytics Italia',
          description:
            'Web Analytics Italia cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
        },
        fr: {
          title: 'Web Analytics Italia',
          description:
            "Les cookie de Web Analytics Italia sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
        },
      },
    },
    // ...config.settings[
    //   'volto-gdpr-privacy'
    // ].defaultPanelConfig.technical.choices.filter(
    //   (f) => f.config_key !== 'GANALYTICS',
    // ),
  ];
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling.choices =
    [];

  config.blocks.blocksConfig = {
    ...omit(config.blocks.blocksConfig, [
      'teaserGrid',
      'imagesGrid',
      'teaser',
      'accordion',
      'video',
      'maps',
    ]),
    'script-embed': {
      ...config.blocks.blocksConfig.html,
      id: 'script-embed',
      title: 'Embed Script',
      view: ScriptEmbedView,
      edit: ScriptEmbedEdit,
      mostUsed: true,
      sidebarTab: 1,
    },
    text: {
      ...config.blocks.blocksConfig.text,
      edit: TextEdit,
      view: TextView,
      sidebarTab: 1,
    },
    dashboardContesto: {
      id: 'dashboardContesto',
      title: 'Dashboard contesto',
      icon: noteSvg,
      group: 'homePage',
      view: DashboardContestoView,
      edit: DashboardContestoEdit,
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    elencoLink: {
      id: 'elencoLink',
      title: 'Elenco Link',
      icon: noteSvg,
      group: 'homePage',
      view: ElencoLinkView,
      edit: ElencoLinkEdit,
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    anchor: {
      id: 'anchor',
      title: 'Ancora',
      icon: linkSvg,
      group: 'common',
      view: AnchorView,
      edit: AnchorEdit,
      restricted: false,
      mostUsed: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    tabs: {
      id: 'tabs',
      title: 'Tabs',
      icon: tabsSvg,
      group: 'common',
      view: TabsView,
      edit: TabsEdit,
      restricted: false,
      mostUsed: true,
      blockHasOwnFocusManagement: true,
      sidebarTab: 1,
      security: {
        addPermission: [],
        view: [],
      },
    },
    __grid: {
      ...config.blocks.blocksConfig.__grid,
      gridAllowedBlocks: [
        // 'teaser',
        'image',
        'listing',
        'slate',
        'text',
        'script-embed',
      ],
    },
    filtriIndicatoriOutput: {
      id: 'filtriIndicatoriOutput',
      title: 'Filtri Indicatori Output',
      icon: filterSvg,
      group: 'homePage',
      view: FiltriIndicatoriOutputView,
      edit: FiltriIndicatoriOutputEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: 0,
      security: {
        addPermission: [],
        view: [],
      },
    },
  };

  // config.blocks.blocksConfig.video.mostUsed = false;
  config.blocks.blocksConfig.rssBlock.mostUsed = false;
  config.blocks.blocksConfig.form.mostUsed = false;
  config.blocks.blocksConfig.highlitedContent.mostUsed = false;
  config.blocks.blocksConfig.numbersBlock.mostUsed = false;

  config.widgets.id.area_icon = AreaSelectWidget;
  config.widgets.widget.area_icon = AreaSelectWidget;
  config.widgets.widget.link_icon = IconWidget;

  return config;
}
